.stickyInfo {
  display: contents;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 12;
  padding-bottom: 1px;
  opacity: 1;
  transition: opacity 0.5s ease;

  @media (--tablet-l) {
    margin-top: -32px !important;
    padding-top: 32px !important;
    top: 0 !important;

    &.sticky {
      visibility: hidden;
      height: 0;
      opacity: 0;
    }
  }

  @media (--desktop-l) {
    top: 80px;
    margin-top: -64px;
    padding-top: 64px;
  }
}

.stickyInfoCompact {
  position: sticky;
  top: 0;
  opacity: 0;
  height: 0;
  visibility: hidden;

  @media (--tablet-l) {
    margin-top: -32px !important;
    padding-top: 32px !important;
    top: 0 !important;

    &.sticky {
      visibility: visible;
      height: unset;
      display: contents;
      display: flex;
      flex-direction: column;
      background: white;
      z-index: 12;
      padding-bottom: 1px;
      opacity: 1;
      transition: opacity 0.5s ease;
      position: sticky;
      top: 112px !important;
      &[data-is-primary-nav-enabled='true'] {
        top: 72px !important;
        @media (--desktop-l) {
          top: 80px !important;
        }
      }
    }
  }

  @media (--desktop-l) {
    top: 80px;
    margin-top: -64px;
    padding-top: 64px;
  }
}

.autobizButton {
  padding: 20px 0 0 0;
  text-decoration: underline;
  color: var(--color-mica-blue-500);
  font-size: 16px;
  cursor: pointer;
}

.autobizCentralButton {
  padding: 20px 0;
  text-decoration: underline;
  color: var(--color-mica-blue-500);
  display: flex;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.modalContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (--tablet-l) {
    height: 600px;
    width: 800px;
  }

  & iframe {
    width: 100%;
    height: 100vw;
    border: none;
  }
}

.modalBody {
  padding: 0 !important;
}

.location {
  display: flex;
  align-items: center;
  margin: var(--spacing-5) 0 0;
  padding: 0 0 0 var(--spacing-2);
  font-size: 14px;

  & svg {
    margin: 0 var(--spacing-2) 0 0;
  }
}

.stockUnavailable {
  font-weight: var(--font-weight-bold);
  margin: var(--spacing-6) 0 var(--spacing-1);
}
