.leasingPrice {
  display: flex;
  margin: 0;
  gap: var(--spacing-1);
  align-items: baseline;
}

.leasingPrice {
  padding-bottom: 0;
}

.priceContent,
.leasingPriceText {
  padding-bottom: var(--spacing-2);
  margin: 0;
}

.priceContent {
  font-weight: 700;
  margin: 0;
}
