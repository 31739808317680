.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: var(--spacing-6);
  background: var(--color-primary-600);
  transition: 0.2s, opacity 0.3s linear 1s;
  height: 40px;
  width: 600px;
  box-shadow: none;
  position: relative;
  margin: 0;

  & * {
    -webkit-tap-highlight-color: transparent;
  }

  @media (--mobile-tablet) {
    width: 100vw;
    display: flex;
    position: fixed;
    inset: 0;
    height: 74px;
    background: var(--color-championship-white);
    opacity: 0;
    transition: 0.2s;
    z-index: 100;
    transform: translateY(-10px);
    padding: 0 var(--spacing-6);
    pointer-events: none;

    &::selection {
      color: var(--color-championship-white);
      background: var(--color-primary-400);
    }

    & .input {
      font-size: 16px !important;

      &::selection {
        color: var(--color-championship-white);
        background: var(--color-primary-400);
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      }
    }

    &[data-is-visible='true'] {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);

      & .input {
        font-size: 16px !important;
        font-weight: 400;
        color: var(--color-tarmac-grey-700);

        &::placeholder {
          opacity: 0.3;
        }

        &:-webkit-autofill {
          -webkit-text-fill-color: #000;
          -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        }
      }

      & .searchIcon,
      & .chevronLeft {
        fill: var(--color-tarmac-grey-700);
      }

      & .closeIcon {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  @media (--mobile) {
    height: 64px;
  }

  &:focus-within {
    background: var(--color-championship-white);

    & .input {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-tarmac-grey-700);

      &::placeholder {
        opacity: 0.3;
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      }
    }

    & .searchIcon {
      fill: var(--color-tarmac-grey-700);
    }

    & .closeIcon {
      opacity: 1;
      pointer-events: all;
    }
  }

  &[data-is-loading='true'] {
    & .input {
      opacity: 0.3;
      transition: opacity 0.3s;
    }

    & .spinnerIcon {
      transition: 0.1s;
      opacity: 1;
    }
  }

  &:has(.input:placeholder-shown) .closeIcon {
    opacity: 0;
  }

  @media (--tablet-l) {
    &[data-is-desktop-experiment-on='false'] {
      display: none;
    }
  }
}

.input {
  background: none;
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  color: var(--color-championship-white);
  padding: 0 var(--spacing-10);
  appearance: none;
  transition: 0.2s, opacity 0.3s linear 1s, font-size 0s;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 400;
  height: 40px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.9);
    color: currentColor;
    font-weight: 500;
    transition: 0.2s, font-size 0s;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

.formInnerWrapper {
  width: 100%;
  &[data-is-primary-header='true'] {
    display: flex;
    align-items: center;
    & .spinnerIcon,
    & .searchIcon,
    & .closeIcon {
      position: relative;
    }
  }
}

.wrapper {
  & .searchIcon,
  & .chevronLeft {
    position: absolute;
    font-size: 24px;
    padding: var(--spacing-3);
    left: 0;
    fill: var(--color-championship-white);

    height: 40px;
    width: 40px;
    display: flex;
    place-items: center;
    transition: 0.2s;

    @media (--mobile-tablet) {
      opacity: 0;
    }
  }

  & .chevronLeft {
    opacity: 0;

    @media (--mobile-tablet) {
      opacity: 1;
      padding: var(--spacing-2);
      width: 64px;
    }
  }

  & .spinnerIcon {
    position: absolute;
    font-size: 24px;
    padding: var(--spacing-3);
    right: 0;
    stroke: var(--color-championship-white);

    height: 40px;
    width: 40px;
    display: flex;
    place-items: center;
    transition: 0.3s;
    transition-delay: 2s;
    opacity: 0;
    pointer-events: none;

    @media (--mobile-tablet) {
      stroke: var(--color-tarmac-grey-700);
      z-index: 10;
      position: absolute;
      margin-right: var(--spacing-10);
    }
  }

  & .closeIcon {
    position: absolute;
    font-size: 24px;
    margin: var(--spacing-3);
    height: 20px;
    width: 20px;
    right: 0;
    padding: var(--spacing-1);
    fill: var(--color-championship-white);
    border-radius: 100px;
    background: var(--color-neutral-400);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    cursor: pointer;
  }

  &[data-is-primary-header='true'] {
    width: 100vw;
    position: fixed;
    inset: 0;
    height: 64px;
    background: var(--color-championship-white);
    opacity: 0;
    transition: 0.2s;
    z-index: 100;
    transform: translateY(-10px);
    padding: 0 var(--spacing-6);
    pointer-events: none;

    @media (--tablet-l) {
      position: absolute;
      transform: translateY(0);
      padding: var(--spacing-16) var(--spacing-10);
    }

    @media (--desktop-l) {
      width: auto;
    }

    &::selection {
      color: var(--color-championship-white);
      background: var(--color-primary-400);
    }

    & .input {
      font-size: 16px !important;
      padding: 0 var(--spacing-1);

      &::selection {
        color: var(--color-championship-white);
        background: var(--color-primary-400);
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      }
    }

    &[data-is-visible='true'] {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);

      & .input {
        font-size: 16px !important;
        font-weight: 400;
        padding: 0 var(--spacing-1);
        color: var(--color-tarmac-grey-700);

        &::placeholder {
          opacity: 0.3;
        }

        &:-webkit-autofill {
          -webkit-text-fill-color: #000;
          -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        }
      }

      & .searchIcon {
        fill: var(--color-tarmac-grey-700);
      }
    }

    & .spinnerIcon {
      stroke: var(--color-tarmac-grey-700);
      z-index: 10;
      position: absolute;
      margin-right: var(--spacing-20);
    }
  }
}

.overlayBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  overscroll-behavior: none;
  pointer-events: none;

  @media (--mobile-tablet) {
    &[data-is-visible='true'] {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }

  &[data-is-primary-header='true'] {
    top: 64px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    @media (--tablet-l) {
      top: 72px;
    }

    @media (--desktop-l) {
      top: 80px;
    }

    &[data-is-visible='true'] {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
}
