.attributeWrapperSidebar {
  margin-top: var(--spacing-5);
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    margin-top: var(--spacing-8);
  }

  @media (--tablet-l) {
    margin-top: 0;
  }
}

.vehiclePrettyName,
.vehicleVariantName {
  color: var(--color-neutral-700);
  margin: 0;
}

.vehicleVariantName {
  @media (--mobile) {
    font-size: 14px;
  }
}

.makeModelNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spacing-2);
}

.priceWrapper {
  margin-top: var(--spacing-5);

  @media (--tablet-l) {
    margin-top: var(--spacing-10);
  }
}

.leasingRateWrapper {
  display: block;
}

.contact {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-3);
}

.contactDisclaimer {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-3);
  line-height: 1.5;
}

.logo {
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: var(--spacing-1);
}

.logo svg {
  height: 100%;
  margin-left: var(--spacing-1);
  max-width: 40%;
  position: relative;
  top: 2px;
}
