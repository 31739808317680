/*
* Base
*/
.logo {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  color: inherit;
}

/*
* Colors
*/

.dark_bg {
  fill: var(--color-championship-white);
}
.light_bg {
  fill: var(--color-rac-grey-100);
}
